import React, { useState, useEffect } from 'react';
import '../../common/scss/Common.css';
import { Footer } from '../../common/Footer';
import { Header } from '../../common/Header';
import { DropdownButton } from '../../common/DropdownButton';
import { Hero } from '../../common/Hero';
import { Section } from '../../common/Section';
import { DescriptionSection } from './component/DescriptionSection';
import { MeetOurPeopleSection } from './component/MeetOurPeopleSection';
import { FullTimeTraineeSection } from './component/FullTimeTraineeSection';
import { HallOfFame } from './component/HallOfFame';
import './component/about.css';
import { LoadingProvider } from '../../common/Loading/component/Provider';
import { Spinner_component } from '../../common/Spinner';

function AboutPage() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    const handleScroll = () => setScrollPosition(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <LoadingProvider>
        <Spinner_component />
        <div className='about-main-content'>
          <DropdownButton />
          <div className='Page-Container'>
            <Header />

            <div className='about-parallax-container'>
              <div
                className='about-parallax-layer-1'
                style={{ transform: `translateY(-${scrollPosition * -0.5}px)` }}
              >
                <Hero title={'About Us'} page={'about'} quote={''} />
              </div>

              <div
                className='about-parallax-layer-2'
                style={{ transform: `translateY(-${scrollPosition * 0.45}px)` }}
              >
                <Section title={''} divider={false} whiteBox={true}>
                  <DescriptionSection />
                </Section>
              </div>

              <div
                className='about-parallax-layer-3'
                style={{ transform: `translateY(-${scrollPosition * 0.2}px)` }}
              >
                <Section
                  title={'Meet Our People'}
                  divider={true}
                  whiteBox={false}
                >
                  <MeetOurPeopleSection />
                </Section>
              </div>

              <div
                className='about-parallax-layer-4'
                style={{ transform: `translateY(-${scrollPosition * 0.4}px)` }}
              >
                <Section
                  title={'Full-time Trainee'}
                  divider={true}
                  whiteBox={true}
                >
                  <FullTimeTraineeSection />
                </Section>
              </div>

              <div
                className='about-parallax-layer-5'
                style={{ transform: `translateY(-${scrollPosition * 0.05}px)` }}
              >
                <Section title={'Hall of Fame'} divider={true} whiteBox={false}>
                  <HallOfFame />
                </Section>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </LoadingProvider>
    </div>
  );
}

export default AboutPage;
