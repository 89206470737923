import React from "react";
import { Button } from "react-bootstrap";

import "./component/FixButton.css"

interface prop {
  child: any;
  onClick: () => void;
}

const FixButton: React.FC<prop> = ({child, onClick}: prop) => {
  return (
    <div
      className="fix-button-container"
    >
      <Button
        onClick={onClick}
        className="fix-button"
      >
        {child}
      </Button>
    </div>
  );
};

export default FixButton;