import React from "react";
import "./component/CompanyDirectionSection.css";
import digitalOperation from "../../../../assets/img/digitalOperation.png";
import scaleReliability from "../../../../assets/img/scaleReliability.png";
import analytics from "../../../../assets/img/analytics.png";
import structure from "../../../../assets/img/structure.png";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface Props {}

const CompanyDirectionSection: React.FC<Props> = (props: Props) => {
  return (
    <div className="direction-container">
      <img src={structure} className="structure-image" alt="structure" />
      <Row className="direction-row">
        <Col md className="direction-content">
          <img
            src={digitalOperation}
            className="direction-img"
            alt="digitalOperation"
          />
          <div className="direction-title">Go Digital Operation</div>
          <div className="direction-quote">Enable real-time streaming</div>
          <div className="direction-paragraph">
            We help customers to enable digital operation by leveraging
            real-time streaming technologies, resulting in operating cost
            optimization, detailed workflow process monitoring and real-time
            response to any business situation.
          </div>
        </Col>
        <Col md className="direction-content">
          <img
            src={scaleReliability}
            className="direction-img"
            alt="scaleReliability"
          />
          <div className="direction-title">Scale Reliability</div>
          <div className="direction-quote">
            Infrastructure as code: Cloud Native APP
          </div>
          <div className="direction-paragraph">
            Ensuring enterprise business stability requires scalable, reliable
            and flexible IT infrastructure as well as portable and scalable
            cloud native application. We help customers to build modern IT
            infrastructure with DevOps and Infrastructure as Code framework, in
            addition to cloud native application development.
          </div>
        </Col>
        <Col md className="direction-content">
          <img src={analytics} className="direction-img" alt="analytics" />
          <div className="direction-title">Analytics Onservability</div>
          <div className="direction-quote">Facilitate digital response</div>
          <div className="direction-paragraph">
            Observability is the key to stabilizing enterprise business
            operations. With the same foundation, we leverage MLOps and AIOps to
            facilitate digital responses in operating scenario.
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyDirectionSection;
