import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './component/header.css';
import logo from '../../assets/img/logo/Logo-white.png';
import { Nav } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = () => {
  const [path, setPath] = useState(window.location.pathname);

  const basename =
    process.env.REACT_APP_BRANCH === 'main'
      ? '/'
      : '/projects/company-website/aexl-hosting-dev/';

  return (
    <Offcanvas responsive='md'>
      <Navbar className='nav-color' fixed='top'>
        <Container className='header-logo-container'>
          <Navbar.Brand className='navbar-brand' href={basename}>
            <img src={logo} className='logo' alt='Home' />
          </Navbar.Brand>
        </Container>
        <Container className='pages-container'>
          <Nav.Link
            className={`pages ${path === basename && 'pages-bold'}`}
            href={basename}
          >
            Home
          </Nav.Link>
          <Nav.Link
            className='pages'
            target="_blank" 
            rel="noopener noreferrer"
            href={"https://agileexlab.ai/"}
          >
            LLM
          </Nav.Link>
          <NavDropdown
            title='Program'
            className={`pages ${
              (path === basename + 'program' || path === basename + '31212') &&
              'pages-bold'
            }`}
          >
            <NavDropdown.Item
              className='header-dropdown'
              href={basename + 'program'}
            >
              Internship Program
            </NavDropdown.Item>
            <NavDropdown.Item
              className='header-dropdown'
              href={basename + '31212'}
            >
              3+12+12
            </NavDropdown.Item>
          </NavDropdown>
          {/* <Nav.Link className={`pages ${(path === "/news") && "pages-bold"}`} href="/news">News</Nav.Link> */}
          <Nav.Link
            className={`pages ${path === basename + 'about' && 'pages-bold'}`}
            href={basename + 'about'}
          >
            About Us
          </Nav.Link>
        </Container>
      </Navbar>
    </Offcanvas>
  );
};

export default Header;
