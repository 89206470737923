import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './pages/landing/landing';
import ProgramPage from './pages/program/program';
import ArticlePage from './pages/article/article';
import AboutPage from './pages/about/about';
import { ThreetwelvetwelvePage } from './pages/threetwelvetwelve';

export const Rroutes = () => {
  return (
    <Routes>
      <Route path='/' element={<LandingPage />} />
      <Route path='/program' element={<ProgramPage />} />
      <Route path='/news' element={<ArticlePage />} />
      <Route path='/about' element={<AboutPage />} />
      <Route path='/31212' element={<ThreetwelvetwelvePage />} />
    </Routes>
  );
};
