import React, { useState, useEffect, useRef } from "react";
import "../../../../common/scss/Common.css";
import "./component/OurVisionSection.css";

interface Props {}

const OurVisionSection: React.FC<Props> = (props: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (top < windowHeight) {
          setIsVisible(true);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="vision-container">
      <div className="logo-container" />
      <div className="text-container">
        <div className="title title-left">Our Vision</div>
        <div className="vision-text">
          Bridging the{" "}
          <span ref={ref} className={`${isVisible && "highlight"}`}>
            digital transformation gap
          </span>{" "}
          through a good blend of nurturing talent and provisioning of{" "}
          <span ref={ref} className={`${isVisible && "highlight"}`}>
            innovation technology
          </span>
          .
        </div>
        <div className="vision-text">
          Unleashing their{" "}
          <span ref={ref} className={`${isVisible && "highlight"}`}>
            potential
          </span>{" "}
          through inclusive membership.
        </div>
      </div>
    </div>
  );
};
export default OurVisionSection;
