import React, { useState } from "react";
import "../scss/Common.css";
import "./component/TraineeCard.css";

import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";

interface Props {
  name: string;
  profile: string;
  imgSrc: string;
  feedback: string;
  title: string;
}

function MyVerticallyCenteredModal(modalProp: any) {
  return (
    <Modal
      {...modalProp}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <Row>
          <Col md={3} className="trainee-modal-img-container">
            <div className="trainee-modal-image-content">
              <div className="trainee-modal-image-shape-border">
                <Image
                  className="trainee-modal-image"
                  src={modalProp.imgSrc}
                  roundedCircle
                />
              </div>
            </div>
          </Col>
          <Col md={9} className="trainee-modal-text-container">
            <Stack gap={4}>
              <div>
                <div className="modal-name">{modalProp.name}</div>
                <div className="modal-title">{modalProp.title}</div>
              </div>
              <div>
                <div className="modal-subtitle">Profile</div>
                <div className="modal-about-content">{modalProp.profile}</div>
              </div>
              <div>
                <div className="modal-subtitle">Feedback</div>
                <div className="modal-about-content">{modalProp.feedback}</div>
              </div>
            </Stack>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="modal-footer" />
    </Modal>
  );
}

const TraineeCard: React.FC<Props> = (props: Props) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Card className="trainee-card-container">
        <div className="trainee-card-content">
          <div className="trainee-card-image-container">
            <div className="trainee-image-shape-border">
              <Image
                className="trainee-card-image"
                src={props.imgSrc}
                roundedCircle
              />
            </div>
          </div>
          <Card.Text className="trainee-card-name">{props.name}</Card.Text>
        </div>
        <Card.Footer className="trainee-card-footer">
          <div
            className="trainee-card-readMore"
            onClick={() => setModalShow(true)}
          ></div>
        </Card.Footer>
      </Card>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        imgSrc={props.imgSrc}
        profile={props.profile}
        name={props.name}
        title={props.title}
        feedback={props.feedback}
      />
    </>
  );
};

export default TraineeCard;
