import React from "react";

import "./component/ObservabilitySection.css";
import observability from "../../../../assets/img/observability.png";
import realTimeInsight from "../../../../assets/img/real-time-insight.png";
import timeSeriesDataCollection from "../../../../assets/img/time-series-data-collection.png";
import distributedSystemVisibility from "../../../../assets/img/distributed-system-visibility.png";
import collaborationAndKnowledgeSharing from "../../../../assets/img/collaboration-and-knowledge-sharing.png";

import { Card, Col, Row } from "react-bootstrap";

interface Props { };

const ObservabilitySection: React.FC<Props> = (props: Props) => {
  const data: { img: string, title: string, content: string }[] = [
    {
      img: realTimeInsight,
      title: "Real-Time Insights",
      content: `
        Observability empowers organizations with real-time insights into their systems' behavior, 
        performance, and health. By collecting and analyzing data from various sources, 
        it enables proactive monitoring, faster troubleshooting, and timely decision-making, 
        resulting in improved system reliability and customer satisfaction.
      `,
    },
    {
      img: timeSeriesDataCollection,
      title: "Time-Series Data Collection",
      content: `
        Observability emphasizes the collection of granular data points, 
        including metrics, logs, traces, and events. 
        This detailed information enables engineers to drill down into specific components or processes, 
        identify bottlenecks, pinpoint root causes, and optimize system performance, 
        ensuring efficient resource allocation and streamlined operations.
      `,
    },
    {
      img: distributedSystemVisibility,
      title: "Distributed System Visibility",
      content: `
        In the era of distributed systems and microservices, 
        observability becomes even more critical. 
        It provides visibility into the interactions and dependencies between various components, 
        making it easier to trace requests, monitor service-to-service communication, 
        and identify performance issues across the entire system, leading to improved scalability and fault tolerance.
      `,
    },
    {
      img: collaborationAndKnowledgeSharing,
      title: "Collaboration and Knowledge Sharing",
      content: `
        Observability promotes collaboration among teams by providing a shared understanding of the system's behavior. 
        With accessible monitoring dashboards, logs, and metrics, developers, operations, 
        and support teams can collaborate effectively, share insights, and troubleshoot issues collaboratively, 
        fostering a culture of continuous improvement and enhancing overall system reliability.
      `,
    },
  ]

  return (
    <div className="observability-container">
      <img src={observability} className="observability-structure-image" alt="structure" />
      <div className="d-flex justify-content-center align-items-center">
        <Row xs={1} md={2} className="g-4" style={{ width: "920px" }}>
          {data.map((entry, idx) => (
            <Col key={idx}>
              <Card className="observability-container">
                <Card.Img variant="top" src={entry.img} className="observability-img" />
                <Card.Body>
                  <Card.Title className="observability-title">{entry.title}</Card.Title>
                  <Card.Text className="observability-paragraph">
                    {entry.content}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default ObservabilitySection;