import React from 'react';
import Container from 'react-bootstrap/Container';
import './component/VideoEmbed.css';

const VideoEmbed = () => {
  return (
    <Container className='video-container'>
      <div className='ratio ratio-16x9'>
        <iframe
          src='https://www.youtube.com/embed/q6iIXzCh-T8'
          title='YouTube video'
          allowFullScreen
        ></iframe>
      </div>
    </Container>
  );
};

export default VideoEmbed;
