import React from "react";
import './component/DescriptionSection.css'

const DescriptionSection = () =>{
    return(
        <div className="column">
            <p className="text">
            In the ever-changing digital world, Agile eXtreme Lab believes the implementation of Cloud-native infrastructure through Agile methodology to realize infrastructure as-a-code, the holy grail of automation. We strive to provide Cloud Native strategies and innovative technologies to organizations in a sustainable and future proof basis.            </p>
            <p className="text">
            With over 30 years of IT expertise in enterprise, portal, and blockchain in the Shipping and Logistic industry, as well as, using DevOps and Cloud Native application architecture on hybrid cloud since 2015, together with the adaption of the open-source policy in 2017. As pioneers in the enterprise digital transformation, the Agile eXtreme Lab team had previously launched a cloud to hybrid cloud migration in the 3rd quarter of 2020, in which public cloud and private (on-premises) cloud were migrated to a hybrid cloud infrastructure with 6 pilot production apps. With the hybird cloud cutover in the 4th quarter of 2020, five more apps were set in motion on productions. We had initiated more than 3000 micro-serivce instances since then. These are the founding principles of Agile eXtreme Lab, an accomplished specialist with practical experiences in operating multiple K8S clusters, ranging from incident reports to risk management.
            </p>
            <p className="text">
            Along with such worldliness and professionalism, Agile eXtreme Lab also focuses on providing commercial and hands on technical trainings related to digital transformation, which we believe would better equip young and aspiring IT professionals and at the same time fill the void of such knowledge in the IT industry. We envision such initiatives would provide training and talent management that would guide the individual IT professional along as well as realizing IT innovation needed in the fast lane of digital transformation.
            </p>
        </div>
    )
}

export default DescriptionSection;