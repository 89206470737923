import React, { useState } from "react";
import "../scss/Common.css";
import "./component/PeopleCard.css";

import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
interface Props {
  imgSrc: string;
  name: string;
  title: string;
  about: string;
}

function MyVerticallyCenteredModal(modalProp: any) {
  return (
    <Modal
      {...modalProp}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <Row>
          <Col md={4} className="modal-img-container">
            <Image src={modalProp.imgSrc} className="modal-img" />
          </Col>
          <Col md={8} className="people-modal-text-container">
            <Stack gap={4}>
              <div>
                <div className="modal-name">{modalProp.name}</div>
                <div className="modal-title">{modalProp.title}</div>
              </div>
              <div>
                <div className="modal-about">About</div>
                <div className="modal-about-content">{modalProp.about}</div>
              </div>
            </Stack>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="modal-footer" />
    </Modal>
  );
}

const PeopleCard: React.FC<Props> = (props: Props) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Card className="peopleCard-container" onClick={() => setModalShow(true)}>
        <Card.Img variant="top" src={props.imgSrc} className="peopleCard-img" />
        <Card.Body className="peopleCard-content">
          <Card.Title className="peopleCard-name">{props.name}</Card.Title>
          <Card.Text className="peopleCard-title">{props.title}</Card.Text>
        </Card.Body>
      </Card>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        imgSrc={props.imgSrc}
        about={props.about}
        name={props.name}
        title={props.title}
      />
    </>
  );
};

export default PeopleCard;
