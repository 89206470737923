import React from "react";

import "./Intro.css";
import IntroRoadmap from "./component/IntroRoadmap";
import IntroCover from "./component/IntroCover";
import IntroRoadmapMobile from "./component/IntroRoadmapMobile";

interface Props {

}

const Intro: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <IntroCover />
      <div style={{ display: "block" }}>
        <IntroRoadmap />
      </div>
      <IntroRoadmapMobile />
    </div>
  );
}

export default Intro;