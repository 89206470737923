import React from "react";
import { Col, Row } from "react-bootstrap";
import solutionImplementation from "../../../../assets/img/solution_implementation.png";
import coDevelopment from "../../../../assets/img/co_development.png";
import training from "../../../../assets/img/training_service.png";

import "./component/ServiceSection.css";

interface Props { }

const ServiceSection: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <Row className="service-row">
        <Col md className="service-content">
          <img
            src={solutionImplementation}
            className="service-img"
            alt="solutionImplementation"
          />
          <div className="service-title">Solution Implementation</div>
          <div className="service-paragraph">
            We collaborate closely with our clients to understand their unique business needs and objectives.
            Our team designs and implements tailored IT solutions to address specific challenges,
            providing end-to-end project management. We configure and integrate software applications,
            systems, and infrastructure components to create a cohesive solution.
            Thorough testing and quality assurance ensure reliable and efficient results.
            We offer post-implementation support, training, and maintenance to ensure ongoing success.
          </div>
        </Col>
        <Col md className="service-content">
          <img
            src={coDevelopment}
            className="service-img"
            alt="coDevelopment"
          />
          <div className="service-title">Co-Development</div>
          <div className="service-paragraph">
            Working in close collaboration with our clients, 
            we jointly develop customized software applications and products. 
            Our expertise in software development methodologies and technologies allows us to deliver high-quality solutions. 
            Through a transparent and agile development process, we focus on scalability, 
            reliability, and user experience to drive innovation. 
            We provide ongoing support, maintenance, 
            and enhancements to ensure the continued success of the developed applications.
          </div>
        </Col>
        <Col md className="service-content">
          <img src={training} className="service-img" alt="training" />
          <div className="service-title">Training</div>
          <div className="service-paragraph">
            We offer comprehensive training programs tailored to individual or organizational needs. 
            Covering various technology topics, industry best practices, and emerging trends, 
            our training is delivered through workshops and customized sessions. 
            Our approach combines theoretical knowledge with practical hands-on exercises to enhance learning outcomes. 
            Whether addressing skill gaps or enabling individuals and teams to excel, 
            our experienced trainers provide expert guidance and support.
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ServiceSection;