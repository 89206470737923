import React, { useRef, useEffect, useState } from "react";
import "./component/HallOfFameCard.css";

interface Props {
  imgSrc: string;
  name: string;
  title: string;
  quote: string;
  left: boolean;
}

const HallOfFameCard: React.FC<Props> = (props: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (top < windowHeight) {
          setIsVisible(true);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        ref={ref}
        className={`hof-card-container ${
          props.left ? "hof-card-container-left" : "hof-card-container-right"
        } ${isVisible ? "visible" : ""}`}
      >
        {/* <div className={`hof-card-container ${props.left ? 'hof-card-container-left' : 'hof-card-container-right'}`}> */}
        <div
          className={`hof-image-container ${
            props.left
              ? "hof-image-container-left"
              : "hof-image-container-right"
          }`}
        >
          <img className="Hall-Of-Fame-Image" src={props.imgSrc} />
          <p className="hof-quote">{props.quote}</p>
        </div>
        <h4 className="hof-name">{props.name}</h4>
        <hr className="hof-divider" />
        <h5 className="hof-title">{props.title}</h5>
      </div>
    </>
  );
};

export default HallOfFameCard;
