import React, { useState } from "react";

import "./component/IHRSection.css";
import ihr from "../../../../assets/img/ihr.png";

import { Button, Col, Row, Modal } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel'

interface Props { };

interface ModalProps {
  show: boolean;
  handleClose: () => void;
}

const ImageModal: React.FC<ModalProps> = ({ show, handleClose }: ModalProps) => {
  return (
    <Modal show={show} onHide={handleClose} animation={false} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>IHR Diagram</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={ihr} className="structure-image" alt="ihr" />
      </Modal.Body>
    </Modal>
  )
}

const IHRSection: React.FC<Props> = (props: Props) => {
  const [index, setIndex] = useState<number>(0);
  const [show, setShow] = useState(false);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="ihr-container">
      <ImageModal show={show} handleClose={handleClose} />
      <Row xs={1}>
        <Col xs={12} md={7}>
          <Button className="ihr-image-button" onClick={handleShow}>
            <img src={ihr} className="structure-image" alt="ihr" />
          </Button>
        </Col>
        <Col xs={12} md={5}>
          <Carousel activeIndex={index} onSelect={handleSelect} className="ihr-carousel" pause="hover">
            <Carousel.Item>
              <Carousel.Caption className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
                <div>
                  <h2 className="ihr-title">IHR</h2>
                  <p className="ihr-paragraph">
                    This application use event driven architecture to handle the real-time data from <b>HKJC</b> to analyze each race
                    and predict each possible outcome, with heuristics and composbile analyzics component. With the help of Large
                    Language Model.
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
                <div>
                  <h2 className="ihr-title">Event Mesh</h2>
                  <p className="ihr-paragraph">
                    An event mesh is a distributed communication architecture that enables seamless and efficient exchange of events or
                    messages between systems, applications, and services. It promoting real-time data sharing,
                    event-driven workflows, and interoperability.
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
                <div>
                  <h2 className="ihr-title">Disaster Recovery</h2>
                  <p className="ihr-paragraph">
                    Active-passive disaster recovery utilizing private and public cloud combines the benefits of both environments.
                    Data and applications are replicated from the primary private cloud to the backup public cloud in real-time.
                    In the event of a disaster, the public cloud environment is activated, ensuring seamless failover and rapid recovery through IAC automation.
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </div>
  );
}

export default IHRSection;