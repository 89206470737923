import React, { useState } from 'react';
import '../scss/Common.css';
import './component/DropdownButton.css';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ListGroup from 'react-bootstrap/ListGroup';
import { RxHamburgerMenu } from 'react-icons/rx';
import Accordion from 'react-bootstrap/Accordion';

interface Props {}

const DropdownButton: React.FC<Props> = (props: Props) => {
  const [show, setShow] = useState(false);

  const basename =
    process.env.REACT_APP_BRANCH === 'main'
      ? '/'
      : '/projects/company-website/aexl-hosting-dev/';

  return (
    <div className='button-container'>
      <Button
        variant='light'
        className='dropdown-button d-md-none'
        onClick={() => {
          setShow(true);
        }}
      >
        <RxHamburgerMenu />
      </Button>
      <Offcanvas
        show={show}
        onHide={() => {
          setShow(false);
        }}
        placement='end'
        className={'menu'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup variant='flush'>
            <a href={basename} className='menu-button'>
              <ListGroup.Item className='menu-button-item'>HOME</ListGroup.Item>
            </a>
            <Accordion flush defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>PROGRAM</Accordion.Header>
                <Accordion.Body>
                  <ListGroup variant='flush'>
                    <a href={basename + 'program'} className='menu-button'>
                      <ListGroup.Item className='menu-button-item intern-dropdown'>
                        Internship Program
                      </ListGroup.Item>
                    </a>
                    <a href={basename + '31212'} className='menu-button'>
                      <ListGroup.Item className='menu-button-item three-dropdown'>
                        3+12+12
                      </ListGroup.Item>
                    </a>
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <a href={basename + 'about'} className='menu-button'>
              <ListGroup.Item className='menu-button-item'>
                ABOUT US
              </ListGroup.Item>
            </a>
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default DropdownButton;
