import React, { useState, useEffect } from "react";
import "../../common/scss/Common.css";
import { Hero } from "../../common/Hero";
import { Footer } from "../../common/Footer";
import { Header } from "../../common/Header";
import { DropdownButton } from "../../common/DropdownButton";
import { Spinner_component } from "../../common/Spinner";
import { ThreeTwelveTwelve } from "../../common/ThreeTwelveTwelve";
import { ThreeTwelveTwelveMobile } from "../../common/ThreeTwelveTwelve/component/ThreeTwelveTwelveMobile";
import "./component/threetwelvetwelve.css";
import { OurVisionSection } from "./component/OurVisionSection";
import { Section } from "../../common/Section";
import { VideoEmbed } from "../../common/VideoEmbed";

function ThreetwelvetwelvePage() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay of 2 seconds before setting isLoading to false
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 400);

    // Clean up the delay timer on unmount
    return () => clearTimeout(delay);
  }, []);

  return (
    <>
      {isLoading && (
        <>
          <DropdownButton />
          <Header />
          <Spinner_component />
          <Footer />
        </>
      )}
      {!isLoading && (
        <div className="31212-main-content">
          <DropdownButton />
          <div className="Page-Container">
            <Header />
            {/* <Hero title={"3+12+12 Program"} page={"31212"} quote={""} /> */}

            <div className='landing-parallax-layer-1'>
              <Hero
                title={'Equip Talents towards best practice '}
                page={'31212'}
                quote={
                  'We offer competitive package for IT full stack development and a promising career path'
                }
              />
            </div>
            
            <div className='landing-parallax-layer-2'>
              <OurVisionSection />
            </div>

            <div className='landing-parallax-layer-3'>
              <Section title={''} divider={false} whiteBox={true}>
                <VideoEmbed />
              </Section>
            </div>

            <div className="three-twelve-desktop">
              <ThreeTwelveTwelve />
            </div>

            <div className="three-twelve-mobile">
              <ThreeTwelveTwelveMobile />
            </div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
}

export default ThreetwelvetwelvePage;
