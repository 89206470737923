import React, { useContext } from "react";
import { LoadingContext } from "../Loading/component/Context";
import { Spinner } from "react-bootstrap";
import "./component/Spinner.css";

const Spinner_component = () => {
  const { loadingBool } = useContext(LoadingContext);
  return (
    <>
      {loadingBool && (
        <div className="spin">
          <Spinner
            animation="border"
            role="status"
            style={{
              width: "5rem",
              height: "5rem",
              borderWidth: "0.35rem",
              borderColor: "#07374A",
              borderRightColor: "transparent",
            }}
          />
        </div>
      )}
    </>
  );
};

export default Spinner_component;
