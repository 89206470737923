import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../scss/Common.css";
import "./component/ThreeTwelveTwelve.css";

import threeTwelveBg from "../../assets/img/threeTwelveImage.png";
import Button from "react-bootstrap/Button";
import { LearnMoreModal } from "./component/LearnMoreModal";
import bootcamp from "../../assets/img/bootcamp.jpg";

interface Props {}

const ThreeTwelveTwelve: React.FC<Props> = (props: Props) => {
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);

  const [showModal1, setShowModal1] = useState<boolean>(false);
  const [showModal2, setShowModal2] = useState<boolean>(false);
  const [showModal3, setShowModal3] = useState<boolean>(false);
  const [showModal4, setShowModal4] = useState<boolean>(false);
  const [showModal5, setShowModal5] = useState<boolean>(false);

  gsap.registerPlugin(ScrollTrigger);

  const buttonWidth = imageWidth * 0.035;
  const buttonHeight = imageHeight * 0.035;
  const buttonBottom = imageHeight * 0.39;
  const buttonFontSize = imageHeight * 0.015;

  useEffect(() => {
    const buttons = document.querySelectorAll(".image-button");
    const updateButtonPositions = () => {
      buttons.forEach((button: any, index: number) => {
        const buttonPosition = getButtonPosition(index);
        button.style.bottom = `${buttonBottom}px`;
        button.style.left = `${buttonPosition.left}px`;
        button.style.width = `${buttonWidth}px`;
        button.style.height = `${buttonHeight}px`;
        button.style.fontSize = `${buttonFontSize}px`;
      });
    };

    const getButtonPosition = (index: number) => {
      // Define the positions for each button

      const positions = [
        {
          left: imageWidth * 0.404,
        },
        {
          left: imageWidth * 0.504,
        },
        {
          left: imageWidth * 0.604,
        },
        {
          left: imageWidth * 0.704,
        },
        {
          left: imageWidth * 0.804,
        },
      ];
      return positions[index];
    };
    updateButtonPositions();
  }, [imageWidth, imageHeight]);

  useEffect(() => {
    ScrollTrigger.getAll().forEach((ST) => ST.kill());
    gsap.to(".image-container", {
      xPercent: -100,
      ease: "none",
      scrollTrigger: {
        trigger: ".three-container",
        start: "top top",
        end: () => "+=" + imageWidth,
        scrub: true,
        pin: true,
        invalidateOnRefresh: true,
      },
    });
  }, [imageWidth, imageHeight]);

  return (
    <div className="three-container">
      <div className="image-container">
        <img
          className="massiveImage"
          src={threeTwelveBg}
          alt={"3+12+12"}
          onLoad={() => {
            const img = document.querySelector(
              ".massiveImage"
            ) as HTMLImageElement;
            setImageWidth(img.clientWidth);
            setImageHeight(img.clientHeight);
            window.addEventListener("resize", () => {
              setImageWidth(img.clientWidth);
              setImageHeight(img.clientHeight);
            });
          }}
        />
        <Button
          className="learn-more-button-1 image-button "
          size="sm"
          onClick={() => setShowModal1(true)}
        >
          Learn More
        </Button>
        <Button
          className="learn-more-button-2 image-button "
          size="sm"
          onClick={() => setShowModal2(true)}
        >
          Learn More
        </Button>
        <Button
          className="learn-more-button-3 image-button "
          size="sm"
          onClick={() => setShowModal3(true)}
        >
          Learn More
        </Button>
        <Button
          className="learn-more-button-4 image-button "
          size="sm"
          onClick={() => setShowModal4(true)}
        >
          Learn More
        </Button>
        <Button
          className="learn-more-button-5 image-button "
          size="sm"
          onClick={() => setShowModal5(true)}
        >
          Learn More
        </Button>
      </div>

      <LearnMoreModal
        show={showModal1}
        onHide={() => setShowModal1(false)}
        title="BOOTCAMP"
        text={
          <>
            <p>
              In this training, you will first learn how to do the modern
              front-end (React) and backend (Java Spring) programming. With
              these skills, multiple practical projects will be developed and
              implemented by you. You will be guided step-by-step to learn how
              to think, design and implement.
            </p>
            <img
              style={{ width: "90%", margin: "auto" }}
              alt="bootcamp"
              src={bootcamp}
            />
            <p>
              Development is not just about programming a system, it also
              emphasises using efficient methods to improve the productivity to
              have fast-response iterative development cycle. As a result,
              automation and CI/CD elements are also involved in the
              infrastructures in our training.
            </p>
            <p>
              You will learn how to encapsulate applications into a container
              and deploy it on cloud. The underlying system is built on top of
              service-mesh in order to ensure fast, reliable and secure
              communication between different micro-services.
            </p>
            <p>
              Apart from learning the orchestration approaches on Kubernetes,
              you will also learn how to enhance the security on cloud to
              defense the malicious attacks by using the open source firewall
              such as pfSense and ModSecurity.
            </p>
          </>
        }
      />

      <LearnMoreModal
        show={showModal2}
        onHide={() => setShowModal2(false)}
        title="4-9 MONTHS"
        text={
          <>
            <p>
              Adopt the practice into real project [6months] followed by 2 weeks
              adoption refreshment.
            </p>
            <p>
              Trainee will go through a series of pair-programming with senior
              members and with peers. Senior members will provide the
              suggestions and practice the lessons learned from the bootcamp.
              After 3 months of practices, they will start to take the ownership
              to their services.
            </p>
          </>
        }
      />
      <LearnMoreModal
        show={showModal3}
        onHide={() => setShowModal3(false)}
        title="10-15 MONTHS"
        text={
          <>
            <p>Through completion of project into production.</p>
            <p>
              After they have walked through the complete development cycle,
              they will experience the production rollout and support to their
              developed applications. Apart from developing the functions and
              setup the infrastructure for multicloud, they also need to be
              application support so that they know how the operations should
              work as usual.
            </p>
          </>
        }
      />
      <LearnMoreModal
        show={showModal4}
        onHide={() => setShowModal4(false)}
        title="16-21 MONTHS"
        text={
          <>
            <p>Comprehension & expression in complex project.</p>
          </>
        }
      />
      <LearnMoreModal
        show={showModal5}
        onHide={() => setShowModal5(false)}
        title="22-27 MONTHS"
        text={
          <>
            <p>Cooperation and stress on full-stack implementation.</p>
          </>
        }
      />
    </div>
  );
};

export default ThreeTwelveTwelve;
