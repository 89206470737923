import React from 'react';
import '../../common/scss/Common.css';
import { Footer } from '../../common/Footer';
import { Header } from '../../common/Header';
import { DropdownButton } from '../../common/DropdownButton';

function ArticlePage() {
  return (
    <>
      <DropdownButton />
      <div className='Page-Container'>
        <Header/>
        <div className='Page-Content'>

        </div>
        <Footer />
      </div>
    </>
  );
}

export default ArticlePage;