import React from 'react';
import './component/PositionSection.css';
import { BsCheck2Square } from 'react-icons/bs';
import { IconContext } from 'react-icons';

const PositionSection = () => {
  return (
    <div className='list-container'>
      <ul className='list'>
        <IconContext.Provider value={{ className: 'list-icon' }}>
          <div className='list-child'>
            <BsCheck2Square className='list-bullet' />
            <li>
              Final year of undergraduate program of Bachelor of
              computing/IT/science or related subject
            </li>
          </div>
          <div className='list-child'>
            <BsCheck2Square className='list-bullet' />
            <li>
              Experienced with at least 1 programming languages with OOP
              concepts and knowledge (Experienced with Python will be a plus)
            </li>
          </div>
          <div className='list-child'>
            <BsCheck2Square className='list-bullet' />
            <li>Passion for cloud computing and the latest technologies</li>
          </div>
          <div className='list-child'>
            <BsCheck2Square className='list-bullet' />
            <li>
              Able to work under a tight schedule with at least 10 hours per
              week
            </li>
          </div>
          <div className='list-child'>
            <BsCheck2Square className='list-bullet' />
            <li>Able to work with different people and teams</li>
          </div>
          <div className='list-child'>
            <BsCheck2Square className='list-bullet' />
            <li>Good communication skills with teams and colleagues</li>
          </div>
          <div className='list-child'>
            <BsCheck2Square className='list-bullet' />
            <li>
              Good learning attitude of trying new things and technologies
            </li>
          </div>
        </IconContext.Provider>
      </ul>
    </div>
  );
};

export default PositionSection;
