import React from "react";

import suse from "../../../../assets/img/suse.svg";
import azure from "../../../../assets/img/microsoft_azure.svg";
import alicloud from "../../../../assets/img/alibabacloud.svg";

import "./component/PartnerSection.scss"

interface Props { };

const PartnerSection: React.FC<Props> = (props: Props) => {
  return (
    <div className="partner-section-container">
      <div>
        <img src={suse} alt="suse" />
        <img src={azure} alt="azure" />
        <img src={alicloud} alt="alicloud" />
      </div>
    </div>
  );
}

export default PartnerSection;
