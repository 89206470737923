import React from "react";
import "./component/CICDSection.css";
import automation from "../../../../assets/img/cicd_automation.png";
import qualityControl from "../../../../assets/img/quality-control.png";
import continousDeployment from "../../../../assets/img/continousDeployment.png";
import cicd from "../../../../assets/img/cicd.png";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface Props { }

const CICDSection: React.FC<Props> = (props: Props) => {
  return (
    <div className="cicd-container">
      <img src={cicd} className="structure-image" alt="structure" />
      <Row className="cicd-row">
        <Col md className="cicd-content">
          <img
            src={automation}
            className="cicd-img"
            alt="automation"
          />
          <div className="cicd-title">Automation</div>
          <div className="cicd-quote">Integrate in pipeline</div>
          <div className="cicd-paragraph">
            CI/CD emphasizes automation in the software development process, 
            enabling developers to build, test, and deploy code more efficiently. 
            It involves automating the steps involved in integrating code changes, 
            running tests, and packaging. Through automation, 
            CI/CD reduces manual errors, saves time, and improves overall development productivity. 
          </div>
        </Col>
        <Col md className="cicd-content">
          <img
            src={qualityControl}
            className="cicd-img"
            alt="qualityControl"
          />
          <div className="cicd-title">Quality Assurance</div>
          <div className="cicd-quote">
            Auto testing
          </div>
          <div className="cicd-paragraph">
            CI/CD promotes a culture of continuous testing and quality assurance. 
            With each code change, automated tests are triggered to ensure that the software remains functional and bug-free. 
            This emphasizes the importance of having a robust suite of unit tests, integration tests, and end-to-end tests. 
            CI/CD enabling faster feedback loops and reducing the risk of introducing defects into production.
          </div>
        </Col>
        <Col md className="cicd-content">
          <img src={continousDeployment} className="cicd-img" alt="continousDeployment" />
          <div className="cicd-title">Continuous Deployment</div>
          <div className="cicd-quote">Quick deploy to production</div>
          <div className="cicd-paragraph">
            Continuous deployment enables organizations to deliver new features, improvements, 
            and bug fixes to end-users at a rapid pace. 
            This iterative approach allows for faster feedback loops, frequent releases, 
            and the ability to respond quickly to user needs or market demands.
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CICDSection;
