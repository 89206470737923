import React from "react";
import { Button, Col, Row } from "react-bootstrap";

import introCover from "../../../../../../../assets/img/Intro_Cover.png";

interface Props { };

const IntroCover: React.FC<Props> = (props: Props) => {
  return (
    <Row style={{ padding: "4px" }}>
      <Col 
        sm={7}
        md={7}
      >
        <img
          style={{ width: "100%", }}
          src={introCover}
          alt={"intro"}
        />
      </Col>
      <Col
        md={5}
        sm={7}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          <div style={{ marginBottom: "12px" }}>
            <div className="intro-cover-title">
              Transform to Digital
            </div>
            <div className="intro-cover-title-sub">
              Our extensive experience as a global HKhq corporation in Enterprise IT has strengthened our capabilities in developing and managing Cloud Native Applications and Infrastructure within a MultiCloud environment.
            </div>
          </div>
          <div>
            <div className="intro-cover-title">
              Transform to AI
            </div>
            <div className="intro-cover-title-sub">
              Our extensive experience in Enterprise IT has also bolstered our capabilities in developing and managing cutting-edge AI applications and infrastructure using generative technologies within a diverse IT environment.
            </div>
            <div style={{ margin: "4px" }}>
              <Button>
                <a href="https://agileexlab.ai" style={{ color: "white" }}>Learn More About AI</a>
              </Button>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default IntroCover;