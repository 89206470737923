import React from 'react';
import '../scss/Common.css';
import './component/Section.css';
import { Dividers } from '../Dividers';

interface Props {
  title: string;
  children: any;
  whiteBox: boolean;
  divider: boolean;
}

const Section: React.FC<Props> = (props: Props) => {
  return (
    <div className={`${props.whiteBox && 'White-Box'}`}>
      <div className={`${props.whiteBox && 'White-Box-Text'} Page-Content `}>
        <div className='section-container'>
          <div className='title'>{props.title}</div>
          {props.divider && <Dividers />}
          <div className='section-content-container'>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default Section;
