import React from 'react';
import './component/Dividers.css';

const Dividers = () => {
  return (
    <div className='divider'>
      <div className='green-line' />
      <div className='blue-line' />
    </div>
  );
};

export default Dividers;
