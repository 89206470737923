import React from 'react';
import './component/Footer.css';
import Stack from 'react-bootstrap/Stack';
import { MdHomeWork, MdPhone, MdOutlineAlternateEmail } from 'react-icons/md';

import { BsLinkedin } from 'react-icons/bs';

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='footer-content'>
        <div className='footer-above-container'>
          <Stack gap={2}>
            <Stack direction='horizontal' gap={3}>
              <MdHomeWork className='footer-icon' />
              <div className='footer-text'>
                Unit 706, 7/F, Westlands Centre, 20 Westlands Road, Quarry Bay,
                Hong Kong
              </div>
            </Stack>
            <Stack direction='horizontal' gap={3}>
              <MdPhone className='footer-icon' />
              <div className='footer-text'>+852 9668 7386</div>
            </Stack>
            <Stack direction='horizontal' gap={3}>
              <MdOutlineAlternateEmail className='footer-icon' />
              <a className='footer-text' href="mailto:info@agileexlab.net" style={{ color: "white" }}>
                info@agileexlab.net
              </a>
            </Stack>
          </Stack>
        </div>
      </div>
      <div className='footer-divider' />
      <div className='footer-content'>
        <a
          className='footer-linkedIn'
          href='https://www.linkedin.com/company/agile-extreme-lab-limited'
        >
          <BsLinkedin className='footer-icon' />
        </a>
        <div className='copyright'>
          © 2023 Agile Extreme Lab. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
