import React from "react";
import "./component/MeetOurPeopleSection.css";
import { People } from "../../../../common/Strapi";
import { PeopleCard } from "../../../../common/PeopleCard";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Portrait from "../../../../assets/img/portrait/steve_siu.png";

interface Props {}

const MeetOurPeopleSection: React.FC<Props> = (props: Props) => {
  const peopleResult = People();

  return (
    <div className="people-container">
      <div className="top-card-container">
        <img className="top-image" src={Portrait} alt="top" />
        <Card className="top-card">
          <Card.Body className="top-card-text-container">
            <Card.Title className="top-card-name">Steve Siu</Card.Title>
            <Card.Subtitle className="top-card-subtitle">
              Founder & CEO
            </Card.Subtitle>
            <Card.Text className="top-card-about">
              <p>
                Mr. Siu brings to AeXL with 30 years of varied Information
                Systems and Strategic Technology experience. Prior to founding
                AeXL, Mr. Siu was the most senior leader and solely responsible
                for all aspects of Information Systems and Technology for one of
                world’s largest container liners. Mr. Siu last served as CIO and
                director of board.
              </p>
              <p>
                Mr. Siu successfully executed the technology transformation
                roadmap that enabled the company to scale and improve its
                revenue in recent years. Throughout his career Mr. Siu
                demonstrated that he is skilled in building high-performance IS
                organisations in support of company’s business goals and
                mission. From migrating mainframe to client server architecture,
                evangelising Native Cloud application, Machine Learning to
                spearheading the formation of a Global Blockchain Consortium.
              </p>
              <p>
                With the avant-garde leadership mindset, his continual pursuit
                in Innovation, Collaboration, Learning and Leadership has
                resulted in his current initiative; to provide training and
                talent management that would help individual IT professional as
                well as realising IT innovation needed in Enterprises in the
                fast lane of the digital transformation era.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <Container className="people-result-container">
        <Row>
          {peopleResult?.data.data.map((person: any, index: number) => {
            return (
              <>
                {person.attributes.Name !== "Steve Siu" ? (
                  <Col className="people-card-container">
                    <PeopleCard
                      name={person.attributes.Name}
                      title={person.attributes.Title}
                      about={person.attributes.About}
                      imgSrc={
                        "https://aexl-prod.agileexlab.net" +
                        person.attributes.Portrait.data.attributes.url
                      }
                    />
                  </Col>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default MeetOurPeopleSection;
