import React from "react";
import './component/ResponsibilitiesSection.css'
import training from '../../../../assets/img/training.png'

const ResponsibilitiesSection = () => {
    return(
        <div>
            <p className="text">
            Study and analyse big data infrastructure, including defining and tracking its SLA Deploy and operate big data components according to business scenarios
            </p>
            <p className="text">
            Work with development and data science teams to design models and schemas of the data to be fed into the platform, making sure they can be processed in a scalable way and used by analysts efficiently
            As a program member, you will be enrolled into foundation training first and then you will be assigned to either one team to strength you techniques on that domain. Below are path of training:
            </p>
            <div className="img-container"><img className='training_img' src={training} alt="training"/></div>
        </div>
    )
}

export default ResponsibilitiesSection;