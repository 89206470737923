import React from "react";

import 'reactflow/dist/style.css';
import "./component/IntroSection.css"
import Intro from "./component/Intro";

interface Props {

}

const IntroSection: React.FC<Props> = (props: Props) => {
  return (
    <div style={{ marginTop: "70px" }} >
      <Intro />
    </div>
  );
}

export default IntroSection;