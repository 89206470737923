import React from "react";
import './component/OverviewSection.css'

const OverviewSection = () =>{
    return(
        <div>
            <p className="text">
            Would you like to have a head start in a Cloud Computing career? We are a Cloud Computing Consulting firm that specialises in Multi-Cloud and Agile DevSecOps. We believe behind any good technologies is its people. Henceforth, providing training programs for passionate and talented fresh graduates to build their bright career paths is part of our DNA.
            </p>
            <p className="text">
            The <b>Final Year Internship Program</b> is a part-time program that offers seniors of universities undergraduates in Hong Kong, the opportunity to gain hands on commercial experiences in big data infrastructure, Cybersecurity and Agile DevSecOps in a live Multi-cloud environment. Our program pairs interns with experienced development and data science teams to design and implement commercial project. Interns learn not only technical skills as the pedagogy also emphasises in collaboration and camaraderie within and among teams.
            </p>
            <p className="text">
            Our program also provides a complete training on full-stack development and DevSecOps such as mastering Kubernetes, development with CI/CD, as well as service mesh on cloud. After our program, interns will be equipped with concrete and hands-on skills to develop and deploy cloud native application. This is a multi-faceted and well-rounded program indeed.
            </p>
            <p className="text">
            Any intern with extraordinary performance during the program would be offered an opportunity to join our Cloud Engineer Trainee Program after graduation.
            </p>
        </div>
    )
}

export default OverviewSection;