import React from "react";
import "./component/HallOfFame.css";
import { HallOfFameCard } from "../../../../common/HallOfFameCard";
import { Fame } from "../../../../common/Strapi";

const HallOfFame = () => {
  const HallOfFameResult = Fame();

  return (
    <div className="hof-container">
      {HallOfFameResult?.data.data.map((fame: any, index: number) => {
        return (
          <>
            <HallOfFameCard
              imgSrc={
                "https://aexl-prod.agileexlab.net" +
                fame.attributes.Portrait.data.attributes.url
              }
              name={fame.attributes.Name}
              title={fame.attributes.Title}
              quote={fame.attributes.Quote}
              left={index % 2 === 0 ? true : false}
            />
          </>
        );
      })}
    </div>
  );
};

export default HallOfFame;
