import { useEffect, useState, useContext } from "react";
import axios, { AxiosResponse } from "axios";
import { LoadingContext } from "../../../Loading/component/Context";
import { STRAPI_API } from "../../Strapi";

export default function People() {
  const { loadingFinish } = useContext(LoadingContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const url =
    "https://aexl-prod.agileexlab.net/api/people?populate=%2A&pagination[limit]=-1";
  const authentication = {
    headers: {
      Authorization: "Bearer " + STRAPI_API,
    },
  };
  const [fetchedData, setFetchedData] = useState<AxiosResponse | null>(null);
  useEffect(() => {
    const getData = async () => {
      const data = await axios
        .get(url, authentication)
        .then((res) => {
          setFetchedData(res);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
          loadingFinish();
        });
    };
    getData();
  }, []);

  return fetchedData;
}
