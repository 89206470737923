import React, { useState, useEffect } from 'react';
import '../../common/scss/Common.css';
import { Footer } from '../../common/Footer';
import { Header } from '../../common/Header';
import { DropdownButton } from '../../common/DropdownButton';
import { CompanyDirectionSection } from './component/CompanyDirectionSection';
import { Section } from '../../common/Section';
import './component/landing.css'
import { Spinner_component } from '../../common/Spinner'
import CICDSection from './component/CICDSection/CICDSection';
import ObservabilitySection from './component/ObservabilitySection/ObservabilitySection';
import IHRSection from './component/IHRSection/IHRSection';
import IntroSection from './component/IntroSection/IntroSection';
import { FixButton } from '../../common/FixButton';
import PartnerSection from './component/PartnerSection/PartnerSection';
import { BsArrowDownCircle } from 'react-icons/bs';
import ServiceSection from './component/ServiceSection/ServiceSection';

function LandingPage() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay of 2 seconds before setting isLoading to false
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 200);

    // Clean up the delay timer on unmount
    return () => clearTimeout(delay);
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const handleScroll = () => setScrollPosition(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      {isLoading && <>
        <DropdownButton />
        <Header />
        <Spinner_component />
        <Footer />
      </>}
      <FixButton child={
        <><BsArrowDownCircle style={{ marginRight: "4px" }} />Query more</>
      } onClick={() => window.location.href = '#footer'} />

      {!isLoading && <div className='landing-main-content'>
        <DropdownButton />
        <div className='Page-Container'>
          <Header />
          <div className='landing-parallax-container'>
            <div className="landing-parallax-layer-1">
              <IntroSection />
            </div>
            <div className='landing-parallax-layer-2' style={{ transform: `translateY(-${scrollPosition * 0}px)` }}>
              <Section
                title={'Company Direction'}
                divider={true}
                whiteBox={false}
              >
                <CompanyDirectionSection />
              </Section>
            </div>

            <div className='landing-parallax-layer-3' style={{ transform: `translateY(-${scrollPosition * 0.005}px)` }}>
              <Section
                title={'Continous Integration / Continous Delivery (CICD)'}
                divider={true}
                whiteBox={true}
              >
                <CICDSection />
              </Section>
            </div>

            <div className='landing-parallax-layer-4' style={{ transform: `translateY(-${scrollPosition * 0.005}px)` }}>
              <Section
                title={'Observability'}
                divider={true}
                whiteBox={false}
              >
                <ObservabilitySection />
              </Section>
            </div>

            <div className='landing-parallax-layer-5' style={{ transform: `translateY(-${scrollPosition * 0.005}px)` }}>
              <Section
                title={'Intelligent Hint Racecard (IHR)'}
                divider={true}
                whiteBox={true}
              >
                <IHRSection />
              </Section>
            </div>

            <div className='landing-parallax-layer-6' style={{ transform: `translateY(-${scrollPosition * 0.005}px)` }}>
              <Section
                title={'Partnership'}
                divider={true}
                whiteBox={false}
              >
                <PartnerSection />
              </Section>
            </div>

            <div className='landing-parallax-layer-7' style={{ transform: `translateY(-${scrollPosition * 0.005}px)` }}>
              <Section
                title={'Our Service'}
                divider={true}
                whiteBox={true}
              >
                <ServiceSection />
              </Section>
            </div>

          </div>
          <div id="footer">
            <Footer />
          </div>
        </div>
      </div>}
    </div>
  );
}

export default LandingPage;
