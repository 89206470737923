import React from 'react';
import '../scss/Common.css';
import './component/Hero.css';

interface Props {
  title: string;
  quote?: string;
  page: string;
}

const Hero: React.FC<Props> = (props: Props) => {
  return (
    <div className='hero-container'>
      {props.page === "landing" && (
        <section className='hero-image hero-landing'>
          <div className='hero-logo-container'>
            <div className='hero-logo' />
          </div>
        <h1 className= 'landing-title'>
          {props.title}
        </h1>
        <p className='landing-quote'>"{props.quote}"</p>
      </section>
      )}
      
      {props.page === "program" && (
        <section className='hero-image hero-program'>
        <h1 className='hero-title'>
          {props.title}
        </h1>
      </section>
      )}

      {props.page === "about" && (
        <section className='hero-image hero-about'>
        <h1 className= 'hero-title'>
          {props.title}
        </h1>
      </section>
      )}

      {props.page === "31212" && (
        <section className='hero-image hero-31212'>
        <h1 className= 'hero-title'>
          {props.title}
        </h1>
      </section>
      )}
    </div>
  );
};

export default Hero;
