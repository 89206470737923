import React, { useRef, useEffect, useState } from "react";
import "../scss/Common.css";
import "./component/ClassRibbon.css";

interface Props {
  class: string;
}

const ClassRibbon: React.FC<Props> = (props: Props) => {
  const [words, setWords] = useState<string[]>();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const wordsRef = useRef<string[]>([]);

  useEffect(() => {
    const initialWords = Array.from({ length: 100 }, () => props.class);
    setWords(initialWords);
    wordsRef.current = initialWords;
  }, [props.class]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollTop } = document.documentElement;
        scrollContainerRef.current.scrollLeft = scrollTop;
      }
    };

    window.addEventListener("scroll", handleScroll);
  }, [props.class]);

  return (
    <div className="horizontal-scroll-container" ref={scrollContainerRef}>
      <div className="horizontal-scroll">
        {words?.map((word: string, index: number) => (
          <span className="class-tag" key={index}>
            {word}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ClassRibbon;
