import React, { useState, useEffect } from "react";
import "../../common/scss/Common.css";
import { Footer } from "../../common/Footer";
import { Header } from "../../common/Header";
import { DropdownButton } from "../../common/DropdownButton";
import { Hero } from "../../common/Hero";
import { Section } from "../../common/Section";
import { OverviewSection } from "./component/OverviewSection";
import { PositionSection } from "./component/PositionSection";
import { ResponsibilitiesSection } from "./component/ResponsibilitiesSection";
import { OurInternSection } from "./component/OurInternSection";
import "./component/program.css";
import { LoadingProvider } from "../../common/Loading/component/Provider";
import { Spinner_component } from "../../common/Spinner";

function ProgramPage() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    const handleScroll = () => setScrollPosition(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      {/* <LoadingProvider>
        <Spinner_component /> */}
        <div className="program-main-content">
          <DropdownButton />
          <div className="Page-Container">
            <Header />

            <div className="program-parallax-container">
              <div
                className="program-parallax-layer-1"
                style={{ transform: `translateY(-${scrollPosition * -0.5}px)` }}
              >
                <Hero
                  title={"Agile Internship Program"}
                  page={"program"}
                  quote={""}
                />
              </div>

              <div
                className="program-parallax-layer-2"
                style={{ transform: `translateY(-${scrollPosition * 0.45}px)` }}
              >
                <Section title={"Overview"} divider={false} whiteBox={true}>
                  <OverviewSection />
                </Section>
              </div>

              <div
                className="program-parallax-layer-3"
                style={{ transform: `translateY(-${scrollPosition * 0.2}px)` }}
              >
                <Section
                  title={"Position Requirements"}
                  divider={true}
                  whiteBox={false}
                >
                  <PositionSection />
                </Section>
              </div>

              <div
                className="program-parallax-layer-4"
                style={{ transform: `translateY(-${scrollPosition * 0.4}px)` }}
              >
                <Section
                  title={"Your Responsibilities"}
                  divider={true}
                  whiteBox={true}
                >
                  <ResponsibilitiesSection />
                </Section>
              </div>
              {/* <ThreeTwelveTwelve /> */}
              <div
                className="program-parallax-layer-5"
                style={{ transform: `translateY(-${scrollPosition * 0.05}px)` }}
              >
                {/* <Section title={"Our Intern"} divider={true} whiteBox={false}>
                  <OurInternSection />
                </Section> */}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      {/* </LoadingProvider> */}
    </div>
  );
}

export default ProgramPage;
