import React, { useState } from "react"

import longImageNew from "../../../../../../../assets/img/Company_Website_Long_Image_Mobile.png";

import { Button, ListGroup, Modal } from "react-bootstrap"

import "./IntroRoadmapMobile.css";

interface Props { }

interface ModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  text: any;
}

const RoadmapModal: React.FC<ModalProps> = ({ show, onHide, title, text }: ModalProps) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{text}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

const IntroRoadmapMobile: React.FC<Props> = (props: Props) => {
  const [showModal1, setShowModal1] = useState<boolean>(false);
  const [showModal2, setShowModal2] = useState<boolean>(false);
  const [showModal3, setShowModal3] = useState<boolean>(false);
  const [showModal4, setShowModal4] = useState<boolean>(false);
  const [showModal5, setShowModal5] = useState<boolean>(false);
  const [showModal6, setShowModal6] = useState<boolean>(false);

  return (
    <div>
      <div className="intro-container-mobile">
        <img
          className="intro-massiveImage-mobile"
          src={longImageNew}
          alt={"intro"}
          style={{
            display: 'block',
            margin: '0 auto',
            width: "75%"
          }}
        />
        <Button
          className="intro-button-mobile-1 intro-button"
          size="lg"
          onClick={() => setShowModal1(true)}
        >
          Learn More
        </Button>
        <Button
          className="intro-button-mobile-2 intro-button"
          size="lg"
          onClick={() => setShowModal2(true)}
        >
          Learn More
        </Button>
        <Button
          className="intro-button-mobile-3 intro-button"
          size="lg"
          onClick={() => setShowModal3(true)}
        >
          Learn More
        </Button>
        <Button
          className="intro-button-mobile-4 intro-button"
          size="lg"
          onClick={() => setShowModal4(true)}
        >
          Learn More
        </Button>
        <Button
          className="intro-button-mobile-5 intro-button"
          size="lg"
          onClick={() => setShowModal5(true)}
        >
          Learn More
        </Button>
        <Button
          className="intro-button-mobile-6 intro-button"
          size="lg"
          onClick={() => setShowModal6(true)}
        >
          Learn More
        </Button>
      </div>
      <div>
        <RoadmapModal
          show={showModal1}
          onHide={() => setShowModal1(false)}
          title="Fast Mutli-Cloud Application Development"
          text={(
            <div>
              <ListGroup>
                <ListGroup.Item>Leveraging experience in large-scale software development</ListGroup.Item>
                <ListGroup.Item>Demonstrating Cloud Native Applications under multiCloud real-time streaming architecture</ListGroup.Item>
                <ListGroup.Item>Offering resilience and cost-effective configuration</ListGroup.Item>
              </ListGroup>
            </div>
          )}
        />
        <RoadmapModal
          show={showModal2}
          onHide={() => setShowModal2(false)}
          title="Digital Transformation with DevOps"
          text={(
            <div>
              <ListGroup>
                <ListGroup.Item>Employing Agile software product development for digital and AI transformations</ListGroup.Item>
                <ListGroup.Item>Using CI/CD framework for lifecycle management</ListGroup.Item>
                <ListGroup.Item>Tracking product releases and facilitating changes</ListGroup.Item>
              </ListGroup>
            </div>
          )}
        />
        <RoadmapModal
          show={showModal3}
          onHide={() => setShowModal3(false)}
          title="Real-Time Application with Advance Observability"
          text={(
            <div>
              <ListGroup>
                <ListGroup.Item>Utilizing Event Mesh to manage real-time data</ListGroup.Item>
                <ListGroup.Item>Implementing observability practices for monitoring and tracing</ListGroup.Item>
                <ListGroup.Item>Assisting engineers in quickly debugging and replaying scenarios</ListGroup.Item>
              </ListGroup>
            </div>
          )}
        />
        <RoadmapModal
          show={showModal4}
          onHide={() => setShowModal4(false)}
          title="Training in Infrastructure and Distribued Development"
          text={(
            <div>
              <ListGroup>
                <ListGroup.Item>Providing staff training in infrastructure essentials and distributed software development</ListGroup.Item>
                <ListGroup.Item>Fostering understanding of architecture design, infrastructure, and business domain segregation</ListGroup.Item>
              </ListGroup>
            </div>
          )}
        />
        <RoadmapModal
          show={showModal5}
          onHide={() => setShowModal5(false)}
          title="Integration, Back-Testing, and Trust-Building Capabilities"
          text={(
            <div>
              <ListGroup>
                <ListGroup.Item>Focusing on integration and back-testing capabilities to build trust</ListGroup.Item>
                <ListGroup.Item>Tracking actual business results against various releases</ListGroup.Item>
                <ListGroup.Item>Back-testing improvements to validate effectiveness</ListGroup.Item>
              </ListGroup>
            </div>
          )}
        />
        <RoadmapModal
          show={showModal6}
          onHide={() => setShowModal6(false)}
          title="Collaboration For Innovative Digital Transformation"
          text={(
            <div>
              <ListGroup>
                <ListGroup.Item>Collaborating with clients to evolve and fine-tune digital and AI roadmap</ListGroup.Item>
                <ListGroup.Item>Ensuring alignment of vision, value, and transformation roadmap</ListGroup.Item>
                <ListGroup.Item>Reimagining business domain innovation to deliver outstanding solutions</ListGroup.Item>
              </ListGroup>
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default IntroRoadmapMobile;
