import React from "react";
import "./component/FullTimeTraineeSection.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Classes } from "../../../../common/Strapi";
import { TraineeCard } from "../../../../common/TraineeCard";
import { ClassRibbon } from "../../../../common/ClassRibbon";

const DescriptionSection = () => {
  const classesResult = Classes();

  return (
    <div>
      {classesResult?.data.data.map((classData: any, index: number) => {
        return (
          <>
            {classData?.attributes.employees.data.some(
              (employee: any) => employee.attributes.isIntern === false
            ) ? (
              <>
                <ClassRibbon class={classData.attributes.Year} />
                {/* <>{classData.attributes.Year}</> */}
                <div className="trainee-cards-wrapper">
                  <Row className="trainee-cards-container">
                    {classData.attributes.employees.data.map(
                      (employee: any, index: number) => {
                        return (
                          <Col xl={3} lg={4} md={6} sm={6}>
                            {!employee.attributes.isIntern ? (
                              <TraineeCard
                                name={employee.attributes.Name}
                                profile={employee.attributes.Profile}
                                feedback={employee.attributes.Feedback}
                                title={employee.attributes.Position}
                                imgSrc={
                                  "https://aexl-prod.agileexlab.net" +
                                  employee.attributes.Portrait.data.attributes
                                    .url
                                }
                              />
                            ) : null}
                          </Col>
                        );
                      }
                    )}
                  </Row>
                </div>
              </>
            ) : null}
            {/* {classData.attributes.employees.data.map(
              (employee: any, index: number) => {
                return <>{employee.attributes.Name}</>;
              }
            )} */}
          </>
        );
      })}
    </div>
  );
};

export default DescriptionSection;
